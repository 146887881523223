import { useLazyQuery, useQuery } from "react-apollo";
import React, { useCallback, useContext } from "react";
import LoadWishlistQuery from "./LoadWishlistQuery.gql";
import ActiveSearchQuery from "web/core/UNSAFE_featureFlags/ActiveSearchQuery.gql";

const WishlistContext = React.createContext({
  isWishlistEnabled: null,
  loadWishlist: () => ({}),
  loadWishlistItem: () => ({}),
});

export const WishlistProvider = ({ children }) => {
  const isWishlistEnabledQueryResults = useQuery(ActiveSearchQuery, {
    displayName: "wishlist",
    variables: { feature: "wishlist" },
  });

  const isWishlistEnabled =
    isWishlistEnabledQueryResults.data &&
    isWishlistEnabledQueryResults.data.isFeatureActive;

  const [startLoadingWishlist, { loading, data, error, called }] =
    useLazyQuery(LoadWishlistQuery);

  const loadWishlist = useCallback(() => {
    if (!isWishlistEnabled) {
      return {};
    }
    if (loading) {
      return { loading: true };
    }
    if (error) {
      return { error };
    }
    if (data) {
      return { wishlist: data.me?.wishlist };
    }
    if (!called) {
      startLoadingWishlist();
    }
    return { loading: true };
  }, [isWishlistEnabled, loading, data, error, called, startLoadingWishlist]);

  const loadWishlistItem = useCallback(
    (sku) => {
      const { loading, error, wishlist } = loadWishlist();
      if (loading) {
        return { loading: true };
      }
      if (error) {
        return { error };
      }
      if (wishlist) {
        const wishlistItem = wishlist.items.find(({ product }) =>
          product ? product.sku === sku : false
        );
        return { isInWishlist: Boolean(wishlistItem), wishlistItem };
      }
      return { isInWishlist: false };
    },
    [loadWishlist]
  );

  return (
    <WishlistContext.Provider
      value={{
        isWishlistEnabled,
        loadWishlist,
        loadWishlistItem,
      }}
    >
      {children}
    </WishlistContext.Provider>
  );
};

const useWishlistContext = () => useContext(WishlistContext);
export const useIsWishlistEnabled = () => {
  const { isWishlistEnabled } = useWishlistContext();
  return isWishlistEnabled;
};
export const useLoadWishlist = () => {
  const { loadWishlist } = useWishlistContext();
  return loadWishlist();
};
export const useLoadWishlistItem = (sku) => {
  const { loadWishlistItem } = useWishlistContext();
  return loadWishlistItem(sku);
};
