import React from "react";
import { Helmet } from "react-helmet-async";
import H1 from "theme/components/atoms/Typography/Heading/H1";
import BodyParagraph from "theme/components/atoms/Typography/Body/BodyParagraph";
import Body from "theme/components/atoms/Typography/Body";
import Logo from "theme/components/atoms/Logo";
import { useIntl, defineMessages } from "react-intl";

const messages = defineMessages({
  seoTitle: {
    id: "pages.Offline.seoTitle",
    defaultMessage: "You're offline",
  },
  title: {
    id: "pages.Offline.title",
    defaultMessage: "You can't access to the website because you are offline",
  },
  content: {
    id: "pages.Offline.content",
    defaultMessage:
      "It seems that you have some issues with your network. Please make sure that you have access to internet before reloading.",
  },
});

const Offline = () => {
  const intl = useIntl();
  return (
    <div className="wrapper wrapper--error">
      <Helmet>
        <title>{intl.formatMessage(messages.seoTitle)}</title>
        <meta httpEquiv="refresh" content="5" />
      </Helmet>

      <div className="header header--static">
        <div className="container header__container">
          <div className="header__logo">
            <Logo />
          </div>
        </div>
      </div>
      <div className="mdl-layout__content">
        <div className="page-content">
          <div className="container">
            <H1>{intl.formatMessage(messages.title)}</H1>
            <BodyParagraph>
              <Body>{intl.formatMessage(messages.content)}</Body>
            </BodyParagraph>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Offline;
