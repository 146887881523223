module.exports = {
  root_categories_path: "1/2/",
  default_image_url: "https://placehold.it/150x220",
  available_page_sizes: [18, 36],
  mapsKey: "AIzaSyB451F91Eed54R7ToFu8_tzH-0IMyN66YI",
  color_attribute_id: "93",
  website_id: 1,
  tax: 1.2,
  contentSecurityPolicy: {
    __dangerouslyDisable: true,
    directives: {
      scriptSrc: [
        "*.odoo.com",
        "*.axept.io",
        "*.googletagmanager.com",
        "*.google-analytics.com",
        "*.imgix.net",
        "*.google.com",
        "www.paypalobjects.com",
        "*.paypal.com",
        "static.payzen.eu",
        "secure.lyra.com",
        "api.lyra.com",
      ],
      frameSrc: [
        "www.paypalobjects.com",
        "*.paypal.com",
        "static.payzen.eu",
        "secure.lyra.com",
        "api.lyra.com",
        "*",
      ],
      styleSrc: [
        "*.odoo.com",
        "*.axept.io",
        "*.googletagmanager.com",
        "*.google-analytics.com",
        "*.imgix.net",
        "*.google.com",
        "static.payzen.eu",
        "secure.lyra.com",
        "api.lyra.com",
      ],
      imgSrc: [
        "*.adyen.com",
        "*.odoo.com",
        "*.axept.io",
        "*.googletagmanager.com",
        "*.google-analytics.com",
        "*.imgix.net",
        "*.google.com",
        "www.paypalobjects.com",
        "*.paypal.com",
        "static.payzen.eu",
        "secure.lyra.com",
        "api.lyra.com",
      ],
      connectSrc: [
        "*.adyen.com",
        "*.odoo.com",
        "*.axept.io",
        "*.googletagmanager.com",
        "*.google-analytics.com",
        "*.imgix.net",
        "www.paypalobjects.com",
        "*.paypal.com",
        "*",
      ],
      baseUri: [],
    },
  },
  homepage_identifier: "home",
  defaultTitle: "Robertet",
  defaultDescription: "Site E-Robertet",
  themeColor: "#002c52",
  search: {
    dynamicFacetSize: 10,
    ignoredAttributeKeys: [
      "url_key",
      "description",
      "image",
      "status",
      "tax_class_id",
      "short_description",
    ],
    attributeFacetMinimumDocumentCount: 1,
    authorizedCategoriesFacet: ["name"],
    categoryFacetMinimumDocumentCount: 1,
  },
  phoneNumber: "05 61 40 54 92",
  email: "contact@front-commerce.com",
  rewrittenToRoot: ["/home"],
  useUserTitles: false,
  reportOnlyDirectives: {
    frameSrc: true,
  },
};
