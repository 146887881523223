import React from "react";
import PropTypes from "prop-types";
import invariant from "fbjs/lib/invariant";
import classNames from "classnames";
import {
  IoIosHome,
  IoIosPerson,
  IoIosArrowForward,
  IoIosArrowBack,
  IoIosArrowDropright,
  IoIosArrowDropleft,
  IoIosArrowDropup,
  IoIosArrowDropdown,
  IoIosAdd,
  IoIosRemove,
  IoIosAddCircle,
  IoIosRemoveCircle,
  IoIosCloseCircle,
  IoIosArrowUp,
  IoIosArrowDown,
  IoIosExit,
  IoIosClose,
  IoIosPhonePortrait,
  IoIosCheckmarkCircle,
  IoIosCheckmark,
  IoIosBusiness,
  IoIosLock,
  IoIosCalendar,
  IoIosCard,
  IoIosArrowRoundDown,
  IoIosShirt,
  IoIosHappy,
  IoIosCog,
  IoIosEye,
  IoMdCreate,
  IoIosTrash,
  IoIosMail,
  IoIosCamera,
  IoIosSync,
  IoIosMenu,
  IoIosList,
  IoIosRedo,
  IoIosCart,
  IoIosHeart,
  IoIosSearch,
  IoIosCash,
  IoIosCube,
  IoIosStar,
  IoIosStarHalf,
  IoIosOptions,
  IoIosDownload,
  IoIosUndo,
  IoMdShare,
  IoMdDocument,
  IoIosChatboxes,
  IoIosEyeOff,
  IoIosNotifications,
  IoIosNotificationsOff,
} from "react-icons/io";

import { FaCookieBite } from "react-icons/fa";

const keyToComponent = {
  home: IoIosHome,
  user: IoIosPerson,
  "chevron-right": IoIosArrowForward,
  "chevron-left": IoIosArrowBack,
  "chevron-up": IoIosArrowUp,
  "chevron-down": IoIosArrowDown,
  "chevron-right-circle": IoIosArrowDropright,
  "chevron-left-circle": IoIosArrowDropleft,
  "chevron-up-circle": IoIosArrowDropup,
  "chevron-down-circle": IoIosArrowDropdown,
  plus: IoIosAdd,
  minus: IoIosRemove,
  "plus-circle": IoIosAddCircle,
  "circle-minus": IoIosRemoveCircle,
  "cross-circle": IoIosCloseCircle,
  exit: IoIosExit,
  cross: IoIosClose,
  "phone-handset": IoIosPhonePortrait,
  "checkmark-circle": IoIosCheckmarkCircle,
  checkmark: IoIosCheckmark,
  store: IoIosBusiness,
  lock: IoIosLock,
  "calendar-full": IoIosCalendar,
  "credit-card": IoIosCard,
  "sort-amount-asc": IoIosArrowRoundDown,
  shirt: IoIosShirt,
  smile: IoIosHappy,
  cog: IoIosCog,
  eye: IoIosEye,
  pencil: IoMdCreate,
  trash: IoIosTrash,
  envelope: IoIosMail,
  camera: IoIosCamera,
  magnifier: IoIosSearch,
  sync: IoIosSync,
  menu: IoIosMenu,
  list: IoIosList,
  redo: IoIosRedo,
  cart: IoIosCart,
  heart: IoIosHeart,
  cash: IoIosCash,
  cube: IoIosCube,
  star: IoIosStar,
  "star-half": IoIosStarHalf,
  options: IoIosOptions,
  download: IoIosDownload,
  undo: IoIosUndo,
  share: IoMdShare,
  "payment-sepa": IoIosCard,
  "payment-visa": IoIosCard,
  "payment-mastercard": IoIosCard,
  "payment-paypal": IoIosCard,
  "chat-odoo": IoIosCard,
  document: IoMdDocument,
  chat: IoIosChatboxes,
  cookies: FaCookieBite,
  "eye-off": IoIosEyeOff,
  notification: IoIosNotifications,
  "notification-off": IoIosNotificationsOff,
};

export const whitelist = Object.keys(keyToComponent).sort();

const svgIcons = [
  "credit-card",
  "payment-sepa",
  "payment-visa",
  "payment-mastercard",
  "payment-paypal",
  "chat-odoo",
];

const wrapperComponents = {
  default: ({ children }) => {
    return children;
  },
  withAppearance: ({ children, appearance, size }) => {
    const className = classNames("icon-wrapper", {
      [`icon-wrapper--${appearance}`]: appearance,
      [`icon-wrapper--${size}`]: size,
    });
    return <span className={className}>{children}</span>;
  },
};

const Icon = ({ icon, size, title, appearance }) => {
  invariant(
    whitelist.indexOf(icon) > -1,
    `"${icon}" is not a whitelisted icon`
  );

  if (process.env.NODE_ENV === "development" && title === undefined) {
    console.warn(
      "You must set a title (it can be an empty string if you don't want one) to use <Icon />."
    );
  }

  const className = classNames("icon", {
    [`icon--${size}`]: size,
    [`icon--${appearance}`]: appearance,
    [`icon--${icon}`]: icon,
  });

  const Wrapper = appearance
    ? wrapperComponents.withAppearance
    : wrapperComponents.default;

  if (svgIcons.indexOf(icon) > -1) {
    return (
      <Wrapper appearance={appearance} size={size}>
        <img
          title={title}
          src={`/images/icons/${icon}.svg`}
          alt=""
          className={className}
        />
      </Wrapper>
    );
  } else {
    const IconComponent = keyToComponent[icon];
    return (
      <Wrapper appearance={appearance} size={size}>
        <IconComponent title={title} className={className} />
      </Wrapper>
    );
  }
};

Icon.defaultProps = {
  size: "default",
  appearance: "default",
};

Icon.propTypes = {
  icon: PropTypes.string.isRequired,
  appearance: PropTypes.oneOf([
    "default",
    "default-block",
    "block",
    "round",
    "round-border",
  ]),
  size: PropTypes.oneOf(["default", "small", "mini", "big"]),
  title: PropTypes.string.isRequired,
};

export default Icon;
