module.exports = {
  robertet_fr: {
    locale: "fr-FR",
    currency: "EUR",
    default_country_id: "FR",
    url: process.env.FRONT_COMMERCE_URL,
    countries: (IsoCountries) =>
      IsoCountries.registerLocale(require("i18n-iso-countries/langs/fr.json")),
  },
  robertet_en: {
    locale: "en-GB",
    currency: "EUR",
    default_country_id: "UK",
    url: process.env.FRONT_COMMERCE_URL + "/en",
    countries: (IsoCountries) =>
      IsoCountries.registerLocale(require("i18n-iso-countries/langs/en.json")),
  },
};
